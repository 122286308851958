// TopBar.tsx
import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import CustomButton from "./common/CustomButton";

interface Props {
  orderClick?: () => void;
}

const TopBar: React.FC<Props> = ({ orderClick }) => {
  const brandName = useSelector((store: RootState) => store.Client.user.name);
  const customerOrders = useSelector(
    (store: RootState) => store.Items.customerOrders
  );

  return (
    <AppBar
      component="nav"
      sx={{ height: 70, backgroundColor: "primary.main", position: "relative" }}
    >
      <Toolbar className="sticky top-0 flex items-center justify-center w-full h-full text-white">
        <Typography variant="h6" component="h6" className="text-center">
          {brandName?.toUpperCase()}
        </Typography>{" "}
        {customerOrders.length > 0 && (
          <CustomButton
            component="button"
            size="large"
            onClick={orderClick}
            background="#262626"
            sx={{
              padding: "10px 20px",
              position: "absolute",
              right: "20px",
              "&:hover": {
                backgroundColor: "#3f3f3f",
              },
              className: "top-1/2 transform translate-y-1/2 ",
            }}
          >
            My Orders
          </CustomButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
