import { Paper } from "@mui/material";
import CustomButton from "../../../components/common/CustomButton";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";

type BannerProps = {
  bgImage: any;
  height?: string;
  orderClick?: () => void;
};
export const Banner = (props: BannerProps) => {
  const { bgImage, height, orderClick } = props;
  const customerOrders = useSelector(
    (store: RootState) => store.Items.customerOrders
  );
  return (
    <section className="w-full h-full">
      <Paper
        sx={{
          borderRadius: "0",
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: height || "30vh",
        }}
      >
        {customerOrders.length > 0 && (
          <CustomButton
            component="button"
            size="large"
            onClick={orderClick}
            background="background.default"
            sx={{
              borderRadius: "50%",
              border: "2px solid #c34f5a",
              padding: "20px",
              position: "absolute",
              top: "10px",
              color: "text.primary",
              right: "10px",
              "&:hover": {
                backgroundColor: "background.paper",
              },
            }}
          >
            My Orders
          </CustomButton>
        )}
      </Paper>
    </section>
  );
};
