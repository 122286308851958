import {
  CartItem,
  Order,
  ProductInterface,
  ServerOrder,
} from "../types/Generic";
import { Dispatch } from "@reduxjs/toolkit";
import {
  addCart,
  manageModal,
  Modal,
  MODAL_TRIGGER,
} from "../redux/slices/Items";
import { AddCartPayload } from "../types/PayloadActionTypes";
import { ItemClickAction } from "./Constants";
import { submitOrder } from "./APIHelpers";
import { get, set } from "lockr";
import { useEffect, useState } from "react";

export const viewports = {
  MOBILE: "S",
  TABLET: "M",
  DESKTOP: "L",
  DESKTOP_XL: "XL",
};

export const getViewport = (screenWidth: number) => {
  let viewPort = viewports.MOBILE;
  if (screenWidth >= 500 && screenWidth <= 1024) {
    viewPort = viewports.TABLET;
  } else if (screenWidth >= 1024 && screenWidth <= 1250) {
    viewPort = viewports.DESKTOP;
  } else if (screenWidth > 1250) {
    viewPort = viewports.DESKTOP_XL;
  }

  return viewPort;
};
export function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : 0,
      height: isClient ? window.innerHeight : 0,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return;
    }
    let timeoutId: any = 0;

    function handleResize() {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setWindowSize(getSize()), 150);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, []);

  return windowSize;
}

export const addItemClick = (
  item: ProductInterface | CartItem,
  dispatch: Dispatch<any>,
  currentCart: CartItem[],
  action: number
) => {
  const selectedItemExists = currentCart.some(
    (existingItem) => existingItem.id === item.id
  );
  let cart: CartItem[];
  if (action === ItemClickAction.Add) {
    if (selectedItemExists) {
      cart = currentCart.map((itemInCurrentCart) => {
        const clonedItem: CartItem = { ...itemInCurrentCart };
        if (clonedItem.id === item.id) clonedItem.count += 1;
        return clonedItem;
      });
    } else {
      const cartItem = {
        names: item.names,
        categoryId: item.categoryId,
        price: item.price,
        prices: item.prices,
        descriptions: item.descriptions,
        count: 1,
        id: item.id,
        productPhoto: item.productPhoto,
      };
      cart = [...currentCart, cartItem];
    }
  } else {
    cart = currentCart
      .map((cartItem) => {
        const clonedItem: CartItem = { ...cartItem };
        if (clonedItem.id === item.id && clonedItem.count)
          clonedItem.count -= 1;
        return clonedItem;
      })
      .filter((item) => item.count > 0);
  }

  const payload: AddCartPayload = { cart };
  const storageCart = cart.map((item) => {
    const itemCountMap: { [key: string]: number } = {};
    itemCountMap[item.id] = item.count;
    return itemCountMap;
  });
  set("cart", storageCart);
  dispatch(addCart(payload));
};

export const cartClick = (
  selectedItemsCount: number,
  dispatch: Dispatch<any>,
  currentModalState: Modal
) => {
  if (!selectedItemsCount) return;
  dispatch(
    manageModal({
      isOpen: !currentModalState.isOpen,
      triggeredBy: MODAL_TRIGGER.CART,
    })
  );
};

export const removeCartItem = (
  clickedCartItem: CartItem,
  cart: CartItem[],
  dispatch: Dispatch<any>
) => {
  let updatedCart = cart
    .map((item) => {
      const clonedCartItem: CartItem = { ...item };
      if (clickedCartItem.id === clonedCartItem.id) {
        clonedCartItem.count -= 1;
      }
      return clonedCartItem;
    })
    .filter((item) => item.count > 0);

  const payload: AddCartPayload = {
    cart: updatedCart,
  };
  if (!updatedCart.length) {
    dispatch(manageModal({ isOpen: false, triggeredBy: MODAL_TRIGGER.CART }));
  }
  dispatch(addCart(payload));
};

export const clearCart = (cart: CartItem[], dispatch: Dispatch<any>) => {
  set("cart", []);
  const payload: AddCartPayload = {
    cart: [],
  };
  dispatch(addCart(payload));
};

export const money = (amount: number, currencySymbol: string) =>
  `${currencySymbol}${amount.toLocaleString()}`;

export const getCartCount = (cart: CartItem[]) => {
  const cartItems = cart.map((item) => item.count);
  return cartItems.reduce((a, b) => a + b, 0);
};

export const cartPriceTotal = (cart: CartItem[], withMoney = false): string => {
  const calculatedItemPrice = cart.map(
    (cartItem) => cartItem.price * cartItem.count
  );
  // const pricesInCart = cart.map((item) => item.price);
  const total = calculatedItemPrice.reduce(
    (price, currentValue) => price + currentValue,
    0 // Provide an initial value of 0 for cart
  );

  return total.toLocaleString();
};

export const modalClose = (dispatch: Dispatch<any>) =>
  dispatch(manageModal({ isOpen: false, triggeredBy: MODAL_TRIGGER.CLOSE }));

export const orderSubmit = (
  cart: CartItem[],
  clientId: string,
  clientUsername: string,
  dispatch: Dispatch<any>,
  tableNumber: number
) => {
  const order: Order[] = cart.map((item) => ({
    productId: item.id,
    price: item.price,
    names: item.names,
    count: item.count,
  }));
  const requestPayload: ServerOrder = {
    tableNumber,
    clientId,
    order,
    mtId: get("mtId"),
    username: clientUsername,
    orderTotal: parseFloat(cartPriceTotal(cart).replace(/,/g, "")),
  };
  submitOrder(requestPayload, dispatch);
};
