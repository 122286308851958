import { User } from "../../redux/slices/Client";

export const getUserBaseSlug = (user: User, tableNumber: number): string => {
  let baseSlug = `${user.username}/${tableNumber}`;
  if (user.hasSegments) {
    baseSlug = `${user.username}/${user.segment}/${tableNumber}`;
  }

  return baseSlug;
};

export const getClientDetails = () => {
  const urlPath = window.location.pathname;
  const pathList = urlPath.split("/").slice(1); // remove empty string in first index
  const username = pathList[0];
  let segment = "0";
  let tableNumber = "";

  pathList.forEach((slug) => {
    if (Number(slug)) tableNumber = slug;
  });

  if (isNaN(Number(pathList[1]))) {
    segment = pathList[1];
  }

  return {
    username,
    segment,
    tableNumber,
  };
};
