import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { store } from "../redux/store";
import { LandingPage } from "../landing/landing.page";
import HomePage from "./pages/home/home.page";
import { ThemeContextProvider } from "../theme/ThemeContext";
import NotFound from "../NotFound";

const router = () => {
  const { user, tableNumber } = store.getState().Client;
  let baseSlug = `${user.username}/${tableNumber}`;
  if (user.hasSegments) {
    baseSlug = `${user.username}/${user.segment}/${tableNumber}`;
  }

  return createBrowserRouter([
    {
      path: "/",
      element: <LandingPage />,
      errorElement: <NotFound />,
    },
    {
      path: baseSlug,
      element: <HomePage />,
    },
  ]);
};

export const AppV3Router = () => {
  return (
    <ThemeContextProvider>
      <RouterProvider router={router()} />
    </ThemeContextProvider>
  );
};
