import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteRounded from "@mui/icons-material/FavoriteRounded";
import { Box, Grid } from "@mui/material";
import { ProductInterface } from "../../../../types/Generic";
import { money } from "../../../../helpers/ActionHelpers";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { ProductActions } from "./AddToCartButton";
import { setFavorites } from "../../../../redux/slices/Items";
import { get, set } from "lockr";
import ReactCardFlip from "react-card-flip";
import { useState, useEffect } from "react";
import { useThemeContext } from "../../../../theme/ThemeContext";

interface ProductProp {
  product: ProductInterface;
}

export default function Product(props: ProductProp) {
  const { product } = props;
  const cart = useSelector((store: RootState) => store.Items.cart);
  const activeLanguage = useSelector(
    (store: RootState) => store.Client.activeLanguage
  );
  const { themeMode } = useThemeContext();
  const favorites: string[] =
    useSelector((store: RootState) => store.Items.favorites) || [];
  const currency = useSelector(
    (store: RootState) => store.Client.user.currency
  );
  const [flipped, setFlipped] = useState(false);
  const dispatch = useDispatch();
  const productInCart = cart.find((cartItem) => cartItem.id === product.id);
  const productCount = productInCart?.count || 0;
  const screenWidth = window.screen.width;
  let fontSize = "0.75rem";
  if (screenWidth <= 375) {
    fontSize = "0.7rem";
  }
  if (screenWidth >= 700) {
    fontSize = "0.9rem";
  }

  const handleFavoriteClick = () => {
    if (favorites && favorites.includes(product.id)) {
      const filteredFavorites = favorites.filter((fav) => fav !== product.id);
      dispatch(setFavorites(filteredFavorites));
      set("favorites", filteredFavorites);
    } else {
      const favList: string[] = [...favorites, product.id];
      dispatch(setFavorites(favList));
      set("favorites", favList);
    }
  };
  useEffect(() => {
    const returningCustomer = get("returningCustomer");
    if (!returningCustomer) {
      setFlipped(true);
      setTimeout(() => {
        setFlipped(false);
        set("returningCustomer", true);
      }, 1500);
    }
  }, []);

  return (
    <Grid item xs={2} sm={4} md={4}>
      <ReactCardFlip isFlipped={flipped} flipDirection="horizontal">
        <Card
          sx={{
            maxWidth: 345,
            minHeight: 295,
            backgroundColor: "background.default",
            boxShadow:
              themeMode === "dark"
                ? "0px 4px 10px rgba(0, 0, 0, 0.8)"
                : "0px 4px 10px rgba(0, 0, 0, 0.1)",
            border: "1px solid grey",
          }}
          key={"front"}
          onClick={() => setFlipped(!flipped)}
          aria-colindex={1}
        >
          <CardHeader
            sx={{ padding: "10px 15px" }}
            action={
              <IconButton
                aria-label="add to favorites"
                onClick={(e) => {
                  e.stopPropagation();
                  handleFavoriteClick();
                }}
              >
                {favorites && favorites.includes(product.id) ? (
                  <FavoriteRounded color="error" />
                ) : (
                  <FavoriteBorderOutlinedIcon />
                )}
              </IconButton>
            }
          />

          <Box className="relative flex justify-center w-full">
            <img
              src={product.productPhoto?.secureUrl}
              height={110}
              width={110}
              alt="Heineken"
              className="object-cover"
            />
          </Box>

          <CardContent sx={{ padding: "10px 15px" }}>
            <Typography
              className={`${themeMode === "dark" ? "text-[#ccc]" : "text-[#666]"}`}
              align="center"
              sx={{ fontSize: fontSize, fontWeight: 600 }}
              gutterBottom
            >
              {product.names[activeLanguage.iso].toUpperCase()}
            </Typography>
            <Typography
              variant="h6"
              className={`${themeMode === "dark" ? "text-[#ccc]" : "text-[#666]"}`}
              align="center"
            >
              {money(product.price, currency.prefix)}
            </Typography>
          </CardContent>

          <CardActions disableSpacing sx={{ padding: "5px 15px" }}>
            <ProductActions
              product={product}
              cart={cart}
              productCount={productCount}
              dispatch={dispatch}
              disableActions={false}
            />
          </CardActions>
        </Card>

        {/*Back Component*/}
        <Card
          sx={{
            maxWidth: 345,
            minHeight: 295,
            backgroundColor: "background.default",
            boxShadow:
              themeMode === "dark"
                ? "0px 4px 10px rgba(0, 0, 0, 0.8)"
                : "0px 4px 10px rgba(0, 0, 0, 0.1)",
            border: "1px solid grey",
          }}
          key={"back"}
          onClick={() => setFlipped(!flipped)}
        >
          <CardContent sx={{ height: 232, overflowX: "auto" }}>
            <Typography
              variant="body2"
              className={`${themeMode === "dark" ? "text-[#ccc]" : "text-[#666]"}`}
              align="center"
              gutterBottom
            >
              {product.descriptions[activeLanguage.iso]}
            </Typography>
          </CardContent>

          <CardActions>
            <ProductActions
              product={product}
              cart={cart}
              productCount={productCount}
              dispatch={dispatch}
              disableActions={false}
            />
          </CardActions>
        </Card>
      </ReactCardFlip>
    </Grid>
  );
}
