export const Next = (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.91001 20.7867C8.72001 20.7867 8.53001 20.7167 8.38001 20.5667C8.09001 20.2767 8.09001 19.7967 8.38001 19.5067L14.9 12.9867C15.38 12.5067 15.38 11.7267 14.9 11.2467L8.38001 4.72672C8.09001 4.43672 8.09001 3.95672 8.38001 3.66672C8.67001 3.37672 9.15001 3.37672 9.44001 3.66672L15.96 10.1867C16.47 10.6967 16.76 11.3867 16.76 12.1167C16.76 12.8467 16.48 13.5367 15.96 14.0467L9.44001 20.5667C9.29001 20.7067 9.10001 20.7867 8.91001 20.7867Z"
      fill="#fff"
    />
  </svg>
);

export const Back = (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.09 20.7867C15.28 20.7867 15.47 20.7167 15.62 20.5667C15.91 20.2767 15.91 19.7967 15.62 19.5067L9.1 12.9867C8.62 12.5067 8.62 11.7267 9.1 11.2467L15.62 4.72672C15.91 4.43672 15.91 3.95672 15.62 3.66672C15.33 3.37672 14.85 3.37672 14.56 3.66672L8.04 10.1867C7.53 10.6967 7.24 11.3867 7.24 12.1167C7.24 12.8467 7.52 13.5367 8.04 14.0467L14.56 20.5667C14.71 20.7067 14.9 20.7867 15.09 20.7867Z"
      fill="#fff"
    />
  </svg>
);
